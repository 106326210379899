<template>
  <div>
    <div class="text-center mb-2">
      <el-radio-group v-model="field.is_global_variable" @change="setData">
        <el-radio-button :label="false">Custom Field</el-radio-button>
        <el-radio-button :label="true">Global Variable</el-radio-button>
      </el-radio-group>
    </div>
    <el-form label-position="right" v-if="!field.is_global_variable">
      <title-and-description :field="field" />
      <el-row :gutter="30">
        <el-col :span="12">
          <placeholder :field="field" />
        </el-col>
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
        <el-col :span="12">
          <div >
           <el-checkbox v-model="field.add_global">Add to Global Variable</el-checkbox>
          </div>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <div class="text-center" >
        <el-form :inline="true">
          <el-form-item label="List Items Data">
            <el-input
              v-model="newElement.name"
              placeholder="Enter Name"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="newElement.value"
              type="text" 
              @input="handleInput"
              placeholder="Enter Value"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              @click="addElement"
              type="primary"
              icon="el-icon-plus"
              class="f-weight-600"
            >
              ADD
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="vue-data-table-mini mb-2">
     
        <el-table border :data="field.list_data">
          <el-table-column prop="name" label="Name" min-width="250">
            <template slot-scope="scope">
              <el-input v-model="field.list_data[scope.$index].name"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="value" label="Value" width="250">
            <template slot-scope="scope">
              <el-input
                v-model="field.list_data[scope.$index].value"
                type="text"
                @input="handleNumericInput(scope.$index)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="value"
            label="Actions"
            width="100"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                @click="deleteValue(scope.$index)"
                icon="el-icon-delete"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- <el-row :gutter="50">
        <el-col :span="12">
          <div class="form-group">
            <el-form-item label="Options">
              <el-select
                v-model="field.options"
                placeholder="Enter options"
                :clearable="true"
                :allow-create="true"
                :default-first-option="true"
                :filterable="true"
                :multiple="true"
                :no-data-text="''"
              />
            </el-form-item>
          </div>
        </el-col>
      </el-row> -->

      <is-field-required :field="field" />
    </el-form>

    <el-form label-position="right" v-if="field.is_global_variable">
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <div class="form-group">
            <el-form-item label="Global Variable">
              <el-select v-model="field.global_variable_id" @change="setGlobalVariable">
                <el-option
                  v-for="(globalVariable,index) of allGlobalVariables"
                  :key="index"
                  :label="globalVariable.label"
                  filterable
                  :value="globalVariable._id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <placeholder :field="field" />
        </el-col>
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
      </el-row>
      <is-field-required :field="field" />
    </el-form>
  </div>
</template>

<script>
// import TitleAndDescription from "./TitleAndDescription";
// import IsFieldRequired from "./IsFieldRequired";
// import FieldFilledBy from "./FieldFilledBy";
// import Placeholder from "./Placeholder";
import { mapGetters } from "vuex";

export default {
  name:"templates-formComponents-List",
  components: {
    "TitleAndDescription":()=>import("./TitleAndDescription"),
    "IsFieldRequired":()=>import("./IsFieldRequired"),
    "FieldFilledBy":()=>import("./FieldFilledBy"),
    "Placeholder":()=>import("./Placeholder")
  },
  props: ["field"],
  computed: {
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    allGlobalVariables() {
      return this.getAllGlobalVariables
        ? this.getAllGlobalVariables.data || []
        : [];
    }
  },
  data() {
    return {
      newElement: {
        name: "",
        value: "",
      },
      validations: [],
      rowsOptions: [
        {
          title: "Folder 1",
          _id: "1"
        },
        {
          title: "Folder 2",
          _id: "2"
        },
        {
          title: "Folder 3",
          _id: "3"
        }
      ]
    };
  },
  mounted() {
    this.fetchGlobalVaribales();
  },
  methods: {
    addElement() {
      this.newElement.name = this.newElement.name.trim()
      this.newElement.value = this.newElement.value.trim()
      let duplicates = this.field.list_data.filter(e => e.name == this.newElement.name && e.value == this.newElement.value)
      if(this.newElement.name == "" || !this.newElement.name){
         this.$message.error("Please add name!")
      }
      else if(this.newElement.value == "" || !this.newElement.value){
        this.$message.error("Please add value!")
      }
     else if (duplicates.length > 0) {
      this.$message.error("The values already exist!")
    }
      else{
        this.field.list_data.push(this.newElement);
      this.newElement = {
        name: "",
        value: "",
      };
      }
     
    },
    deleteValue(index) {
      this.field.list_data.splice(index, 1);
    },
    setData() {
      if (!this.field.is_global_variable) {
        this.field.options = [];
        this.field.global_variable_id = null;
      }
    },
    async fetchGlobalVaribales() {
      let params = {
        get_all: true,
        input_type: "LIST"
      };

      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariables",
        params
      );
    },
    setGlobalVariable() {
   
      let globalVariable = this.allGlobalVariables.find(
        x => x._id == this.field.global_variable_id
      );
    
      this.field.label = globalVariable.label;
      this.field.description = globalVariable.description;
      this.field.options = globalVariable.options;
      
      this.field.list_data = globalVariable.list_data;
    },
    handleInput() {
      this.newElement.value = this.newElement.value.replace(/[^0-9]/g, '');
     
    },
    handleNumericInput(index) {
    this.field.list_data[index].value = this.field.list_data[index].value.replace(/[^0-9]/g, '');
    if (this.field.list_data[index].value === '') {
      this.field.list_data[index].value = '';
    }
  },
  }
};
</script>

<style lang="scss">
</style>